
.quality-course {
    /*height: calc(100% - 84px);*/
}
.course-now {
    display: flex;
    padding: 50px 20px 20px 40px;
    position: relative;
    .course-cover {
        width: 178px;
        height: 100px;
        overflow: hidden;
        background: #fdf6ef;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .course-info {
        width: 1%;
        flex: 1;
        position: relative;
        margin-left: 10px;
        .title {
            margin: 0;
            font-size: 16px;
            color: #1C1F21;
        }
        .info {
            margin: 8px 0 0;
            font-size: 12px;
            color: #888;
            line-height: 1;
        }
        .info-bottom {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: space-between;
        }
    }
    &:after {
        content: '';
        position: absolute;
        left: 40px;
        right: 20px;
        bottom: 0;
        height: 1px;
        background: #e9e9e9;
    }
}
.course-catalog-detail {
    height: 1%;
    flex: 1;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            padding-bottom: 50px;
        }
    }
    .catalog-detail-wrapper {
        .catalog-title {
            padding: 40px 22px 20px 38px;
            .title {
                margin: 0;
                font-size: 24px;
                padding-left: 15px;
                position: relative;
                height: 40px;
                line-height: 40px;
                color: #404040;
                font-weight: 600;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 7px;
                    height: 24px;
                    width: 3px;
                    background: #fa8919;
                }
            }
            .subtitle {
                margin: 0;
                font-size: 14px;
                line-height: 20px;
                color: #888;
            }
        }
        .collapse-content {
            ::v-deep .el-collapse-item__header {
                padding: 20px 22px 20px 38px;
                font-size: 17px;
                color: #000;
                line-height: 30px;
                font-weight: 600;
                height: auto;
                background-color: transparent;
                span {
                    margin-right: 10px;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            ::v-deep .el-collapse-item {
                &.is-active {
                    .el-collapse-item__header {
                        padding-bottom: 0;
                    }
                }
            }
            ::v-deep .el-collapse-item__wrap {
                background-color: transparent;
                .el-collapse-item__content {
                    padding-bottom: 10px;
                    .chapter-item {
                        padding: 20px 22px 20px 38px;
                        line-height: 17px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #000;
                        cursor: pointer;
                        span {
                            font-size: 14px;
                            width: 235px;
                            margin-left: 14px;
                            padding-left: 14px;
                            line-height: 20px;
                        }
                        .iconfont {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #fa8919;
                            font-size: 16px;
                            padding-right: 8px;
                        }
                        &.current {
                            span {
                                color: #fa8919;
                            }
                        }
                        &:hover {
                            background-color: #fef6ee;
                            color: #fa8919;
                        }
                    }
                }
            }
        }
        .collapse-content-two {
            border-top: none;
            border-bottom: none;
            ::v-deep .el-collapse-item__header {
                font-size: 15px;
                color: #4c4c4c;
                border-bottom: none;
                font-weight: 400;
                span {
                    margin-left: 20px;
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        left: -20px;
                        top: 12px;
                        width: 6px;
                        height: 6px;
                        background: #fa8919;
                    }
                }
            }
            ::v-deep .el-collapse-item__wrap {
                border-bottom: none;
            }
        }
    }
}
.detail-content {
    .detail-title {
        font-size: 28px;
        color: #404040;
        font-weight: 600;
        line-height: 1.5;
        margin-top: 60px;
    }
    .detail-author {
        color: #888;
        font-size: 16px;
        margin-top: 10px;
        span {
            margin-right: 5px;
            font-weight: 500;
        }
    }
    .detail-video {
        margin-top: 30px;
        video {
            display: block;
            outline: none;
        }
    }
    .detail-content {
        margin-top: 40px;
        p {
            margin: 0 0 30px;
            font-size: 17px;
            line-height: 30px;
            color: #353535;
        }
    }
    .detail-copyright {
        color: #b2b2b2;
        font-size: 14px;
        padding: 20px 0;
        margin-top: 60px;
        border-top: 1px solid #e2e2e2;
    }
}
.detail-operate {
    position: absolute;
    top: 60%;
    transform: translateY(-60%);
    &.operate-left {
        left: calc(50% - 340px - 80px);
    }
    &.operate-right {
        right: calc(50% - 340px - 80px);
    }
    .item-operate {
        width: 38px;
        height: 38px;
        line-height: 38px;
        color: #999;
        background: #f6f7f9;
        border: 1px solid #f6f7f9;
        text-align: center;
        border-radius: 50%;
        font-size: 24px;
        margin-top: 20px;
        cursor: pointer;
        &:hover {
            color: #fa8919;
            background: #fff;
            border: 1px solid #fa8919;
        }
    }
}
@media screen and (max-width: 1221px) {
    .quality-course {
        .course-left {
            display: none;
        }
        .course-right {
            .icon-screen {
                transform: rotate(180deg) !important;
            }
        }
    }
}
